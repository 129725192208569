import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import queryString from 'query-string';
import pick from 'lodash.pick';
import omit from 'lodash.omit';
import {DEFAULT_TRANSACTIONS_OPTIONS, TRANSACTIONS_PATH} from '../../constants';
import {
    fetchTransactions,
    selectOptions,
    selectTransactionsPerPage,
    setOptions
} from '../components/Transactions/transactionsSlice';
import getValidPackagesParams from '../utils/getValidPackagesParams';

export default function useTransactionsPageNavigate(onSuccess, navigateImmediately, shouldUseGlobalLoading) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const options = useSelector(selectOptions);
    const transactionsPerPage = useSelector(selectTransactionsPerPage);

    return async (pageOptions = {}, shouldResetPages) => {
        const newOptions = {
            ...DEFAULT_TRANSACTIONS_OPTIONS,
            ...options,
            ...getValidPackagesParams(pageOptions)
        };

        if (shouldResetPages) {
            newOptions.from = DEFAULT_TRANSACTIONS_OPTIONS.from;
            newOptions.to = transactionsPerPage;
        }

        const filteredNewOptions = pick(newOptions, [
            'from',
            'to',
            'sort',
            'dir',
            'query',
            'search',
            'predefined',
            'lastUpdatedStartDate'
        ]);
        const newQuery = queryString.stringify(omit(filteredNewOptions, ['query']));

        dispatch(setOptions(filteredNewOptions));

        if (navigateImmediately) {
            navigate(`${TRANSACTIONS_PATH}/${filteredNewOptions.query}?${newQuery}`);
        }

        const wasSuccessful = await dispatch(fetchTransactions({shouldUseGlobalLoading: true}));

        if (wasSuccessful) {
            if (onSuccess) {
                onSuccess();
            }
            if (!navigateImmediately) {
                navigate(`${TRANSACTIONS_PATH}/${filteredNewOptions.query}?${newQuery}`);
            }
        }
    };
}
